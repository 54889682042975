import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/(home)": [4,[2]],
		"/(internas)/cadastro": [~5,[3]],
		"/(internas)/ganhadores": [~6,[3]],
		"/(internas)/lampada-da-sorte": [~7,[3]],
		"/(internas)/login": [~8,[3]],
		"/(internas)/minha-conta/(meus-numeros-da-sorte)": [~9,[3]],
		"/(internas)/minha-conta/meus-premios": [~10,[3]],
		"/(internas)/minha-conta/minhas-chances": [~11,[3]],
		"/(internas)/politica-de-privacidade": [12,[3]],
		"/(internas)/premios": [13,[3]],
		"/(internas)/regulamento": [14,[3]],
		"/sentry-example": [17],
		"/(internas)/sorteios": [~15,[3]],
		"/(internas)/termos-de-uso": [16,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';